var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));var _extends2=_interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _react=_interopRequireWildcard(require("react"));

var _Application=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/Application"));

var _ApplicationStore=require("@24i/nxg-sdk-smartott/src/context/ApplicationStore");
var _AppStartContext=require("@24i/nxg-sdk-smartott/src/context/AppStartContext");
var _Footer=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/components/Footer"));

var _DefaultDataProviders=require("@24i/nxg-sdk-smartott-shared/src/context/DefaultDataProviders");
var _Query=require("@24i/nxg-sdk-smartott-shared/src/context/Query");
var _DefaultSDKProviders=require("@24i/nxg-sdk-smartott/src/context/DefaultSDKProviders");
var _useStorageLanguageSync=require("@24i/nxg-sdk-smartott/src/hooks/cleeng/useStorageLanguageSync");
var _DefaultClientStubsProvider=require("@24i/nxg-sdk-smartott/src/context/DefaultClientStubsProvider");
var _nxgSdkQuarks=require("@24i/nxg-sdk-quarks");
var _src=require("@24i/adobe-primetime/src");
var _defaultTheme=_interopRequireDefault(require("../../platformAssets/runtime/defaultTheme.json"));
var _config=_interopRequireWildcard(require("./config"));var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/covington/app.js";function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=typeof e&&"function"!=typeof e)return{default:e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n.default=e,t&&t.set(e,n),n;}

var runtimeConfig=_config.default.runtimeConfig,staticSplashConfig=_config.default.staticSplashConfig;

var AppModule=function AppModule(props){
var _useAppStart=(0,_AppStartContext.useAppStart)(),initApplication=_useAppStart.initApplication;

var _props$footerLinks=props.footerLinks,footerLinks=_props$footerLinks===void 0?[]:_props$footerLinks,_props$footerIcons=props.footerIcons,footerIcons=_props$footerIcons===void 0?[]:_props$footerIcons;

(0,_react.useEffect)(function(){
initApplication();
},[]);

return(
_react.default.createElement(_Application.default.Main,(0,_extends2.default)({},
props,{
staticSplashConfig:staticSplashConfig,
renderFooter:function renderFooter(){return _react.default.createElement(_Footer.default,{links:footerLinks,icons:footerIcons,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:35,columnNumber:33}});},__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:32,columnNumber:9}})
));

};

var App=function App(props){
var _useState=(0,_react.useState)(),_useState2=(0,_slicedToArray2.default)(_useState,2),clients=_useState2[0],setClients=_useState2[1];
(0,_react.useEffect)(function(){
setClients((0,_config.getClients)());
},[]);


(0,_useStorageLanguageSync.useStorageLanguageSync)();

if(clients){
return(
_react.default.createElement(_Query.QueryProvider,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:51,columnNumber:13}},
_react.default.createElement(_ApplicationStore.ApplicationStoreProvider,{runtimeConfig:runtimeConfig,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:52,columnNumber:17}},
_react.default.createElement(_DefaultDataProviders.DefaultDataProviders,{clients:clients,options:{defaultTheme:_defaultTheme.default},__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:53,columnNumber:21}},
_react.default.createElement(_DefaultSDKProviders.DefaultSDKProviders,{clients:clients,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:54,columnNumber:25}},
_react.default.createElement(_DefaultClientStubsProvider.DefaultClientStubsProvider,{clients:clients,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:55,columnNumber:29}},

_react.default.createElement(_src.AdobePrimetimeProvider,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:57,columnNumber:33}},
_react.default.createElement(AppModule,(0,_extends2.default)({},props,{clients:clients,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:58,columnNumber:37}}))
)

)
)
)
)
));

}

return _react.default.createElement(_nxgSdkQuarks.ActivityIndicator,{__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:69,columnNumber:12}});
};var _default=

App;exports.default=_default;