import { ViewStyle } from 'react-native';

export type WebViewBackHeaderStyles = {
    wrapper: ViewStyle;
    textContainer: ViewStyle;
};

export const styles: WebViewBackHeaderStyles = {
    wrapper: {
        // @ts-ignore: used for web only
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        width: '100%',
    },
    textContainer: {
        padding: 15,
    },
};
