import { getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { EpgScreenStyles } from './types';

const PageEpg = (theme: Theme, shouldAccomodateTopMenu: boolean): EpgScreenStyles => ({
    container: {
        flex: 1,
        backgroundColor: theme.color.darkerBlur1.color,
        height: '95%',
    },
    header: {
        ...getMargins(),
        marginTop: shouldAccomodateTopMenu ? 95 : 20,
    },
});

export default PageEpg;
