var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.CUSTOM_SCREEN_BUILDERS=void 0;var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));



var _react=_interopRequireDefault(require("react"));
var _screenConfigBuilders=require("@24i/nxg-sdk-smartott/src/navigation/navigationConfig/screenConfigBuilders");
var _index=require("@24i/nxg-sdk-smartott/src/navigation/components/TopBarMenu/components/OpenModalLink/index.web");




var _SettingsScreen=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/screens/SettingsScreen"));
var _SigninScreen=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/screens/SigninScreen"));
var _MvpdSignInScreen=_interopRequireDefault(require("@24i/nxg-sdk-smartott/src/screens/MvpdSignInScreen"));var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/covington/config/customScreens.tsx";function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2.default)(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}

var CUSTOM_SCREEN_BUILDERS=_objectSpread(_objectSpread({},
_screenConfigBuilders.SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS),{},{
buildSignInScreen:function buildSignInScreen(menuItem,context){var _context$features;
var originalBuild=_screenConfigBuilders.SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSignInScreen(
menuItem,
context
);

var adobePrimetime=context==null?void 0:(_context$features=context.features)==null?void 0:_context$features.adobePrimetime;
var adobePrimetimeEnabled=adobePrimetime==null?void 0:adobePrimetime.enabled;

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:_SigninScreen.default.Main
},
renderWebLink:function renderWebLink(_ref){var linkMenuItem=_ref.menuItem;return(
_react.default.createElement(_index.OpenModalLink,{
menuItem:linkMenuItem,

ModalComponent:
adobePrimetimeEnabled?
_MvpdSignInScreen.default.Main:
_SigninScreen.default.Main,__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:33,columnNumber:17}}

));}});


},
buildSettingsScreen:function buildSettingsScreen(menuItem,context){
var originalBuild=_screenConfigBuilders.SOTT_DEFAULT_SCREEN_CONFIG_BUILDERS.buildSettingsScreen(
menuItem,
context
);

return _objectSpread(_objectSpread({},
originalBuild),{},{
nativeRenderOptions:{
Component:_SettingsScreen.default.Main
}});

}});exports.CUSTOM_SCREEN_BUILDERS=CUSTOM_SCREEN_BUILDERS;