import { NavConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../navigation/constants';
import { useCurrentWebPageConfig } from '../../hooks/useCurrentWebPageConfig';
import type { ViewModelProps } from '../types';
import {
    useDeprecatedSetAppState,
    useSharedApplicationInit,
} from '../../context/AppStartContext/hooks';
import { useNavigationConfigQuery } from '../../hooks/query/useNavigationConfigQuery';
import { useOneTrust } from '../../hooks/useOneTrust';

const useViewModel: ViewModelProps = (props) => {
    const { navigationConfig } = useNavigationConfigQuery();
    // We can do the typecast, we know we are on the web version because of the .web
    // TODO: It might be better to handle this more robustly, e.g. via a typeguard
    const navigationConfigWeb = navigationConfig as NavConfigWeb;

    const { currentPageConfig } = useCurrentWebPageConfig();
    const currentPageOptions = currentPageConfig?.optionsWeb;

    useSharedApplicationInit();
    useOneTrust();

    const { appModelState } = useDeprecatedSetAppState();

    const notFoundPage = navigationConfigWeb?.allNavigationItems.find(
        (screen) => screen.name === SOTT_DEFAULT_WEB_SCREENS.NotFound.uniqueWebId
    );
    if (navigationConfigWeb && !notFoundPage) {
        throw new Error(
            `The not found page was not found in the navigation config! Make sure the page is added to the configuration!`
        );
    }

    return {
        ...appModelState,
        showNavBarMenu: currentPageOptions?.showNavBarMenu,
        showFooter: currentPageOptions?.showFooter,
        disableTransition: currentPageOptions?.disableTransition,
        ...props,
    };
};

export { useViewModel };
