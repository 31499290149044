import { isMobile } from 'react-device-detect';
import { useMemo } from 'react';
import useDimensions from '@24i/nxg-sdk-quantum/src/Dimensions/hooks/useDimensions';

// The below are to leave out tablets
const MAX_LANDSCAPE_WIDTH = 950;
const MAX_LANDSCAPE_HEIGHT = 500;

export const useIsMobileLandscape = () => {
    const { isLandscape, viewPortHeight, viewPortWidth } = useDimensions();
    const isMobileLandscape = useMemo(
        () =>
            isMobile &&
            isLandscape &&
            viewPortWidth != null &&
            viewPortWidth < MAX_LANDSCAPE_WIDTH &&
            viewPortHeight != null &&
            viewPortHeight < MAX_LANDSCAPE_HEIGHT,
        [isMobile, isLandscape, viewPortWidth, viewPortHeight]
    );

    return { isMobileLandscape };
};
