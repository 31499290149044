export const BLOCK_TV_FOCUS = 'BLOCK' as const;

export const ASYNC_STORAGE_KEY_USER_TOKEN = 'user-token';
export const ASYNC_STORAGE_KEY_API_USER_TOKEN = 'api-user-token';
export const ASYNC_STORAGE_KEY_USER_LANGUAGE = 'user-language';
export const ASYNC_STORAGE_KEY_MOCK_DEVICES = 'mock-devices';
export const ASYNC_STORAGE_KEY_PIN_CREATED = 'pin-created';
export const ASYNC_STORAGE_KEY_ONBOARDING_PROFILE_SEEN = 'profile-onboarding-seen';
export const ASYNC_STORAGE_ACCEPTED_TERMS_AND_CONDITIONS = 'accepted-terms-conditions';
export const ASYNC_STORAGE_ACCEPTED_TERMS_UPDATED_AT = 'accepted-terms-updated-at';
export const ASYNC_STORAGE_STREAM_ONLY_ON_WIFI = 'stream-only-on-wifi';
export const ASYNC_STORAGE_KEY_ONBOARDING_SUBSCRIPTION = 'onboarding-subscription';
export const ASYNC_STORAGE_KEY_DEFAULT_TEXT_TRACK = 'defaultTextTrack';
export const ASYNC_STORAGE_KEY_DEFAULT_AUDIO_TRACK = 'defaultAudioTrack';
export const ASYNC_STORAGE_KEY_DEFAULT_VOLUME = 'defaultVolume';
export const ASYNC_STORAGE_KEY_PREV_SEARCH_KEYS = 'prev-search-keys';
export const ASYNC_STORAGE_KEY_PIN_FOR_PAYMENTS = 'pin-protection-payments';
export const ASYNC_STORAGE_KEY_IS_FIRST_TIME_LOADED = 'is-first-time-loaded';
export const ASYNC_STORAGE_KEY_LAST_PURCHASE = 'last-purchase';
export const ASYNC_STORAGE_KEY_I18_NEXT_LANG = 'i18nextLng';

export const ASYNC_STORAGE_KEY_USER = 'user-data';

export const EVENT_TV_UPDATE_FOCUS = 'focusableItemNeedsFocusUpdate';

export enum PACKSHOT_ASPECT_RATIOS {
    LANDSCAPE = 1.77, // 16:9
    PORTRAIT = 0.7555,
}

export enum ORIENTATIONS {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait',
}

export enum DVR_POINTS {
    START = 'start',
    END = 'end',
}

export enum TV_REMOTE_CONTROLLER_DIRECTIONS {
    LEFT = 'left',
    RIGHT = 'right',
    UP = 'up',
    DOWN = 'down',
}

export const TIME_24H_30_MIN_INTERVALS = [
    '',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
];

export const TIME_12H_30_MIN_INTERVALS = [
    '',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:30 AM',
    '2:00 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
];

export const WEB_UUID = 'web-uuid';

export const NUMBER_OF_PIN_INPUTS = 4;
export const RESET_TIME = -1;

export const MINUTE_IN_MS = 60000;
export const allExceptNumbersRegex = /\D+/g;

export const MINUTE = 60 * 1000;
export const THIRTY_SECONDS = MINUTE / 2;
export const CLEENG_OPERATION_BUFFER_TIME = MINUTE * 3;

export enum Platform {
    generic = 'generic',
    ios = 'ios',
    tvos = 'tvos',
    android = 'android',
    androidtv = 'androidtv',
    firetv = 'firetv',
    web = 'web',
}

export enum IDENTITY_PROVIDERS {
    FLOWID = 'flowId',
    FLOWID_V2 = 'flowId_cleengV2',
    GENERIC = 'generic',
}

export const MAX_SEARCH_QUERY_SIZE = 500;
export const DEFAULT_SEARCH_QUERY_SIZE = 24;

export const DEFAULT_TIME_FORMAT = 'HH:mm';
