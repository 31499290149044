import CornerLogo, {
    getCornerLogoStyles,
} from '@24i/nxg-sdk-gluons/src/components/ui/CornerLogo/View/index.web';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Interactable, ScrollView, Text, View } from '@24i/nxg-sdk-quarks';
import { TextInputRefType } from '@24i/nxg-sdk-quarks/src/components/TextInput/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingWrapper from '../../../components/LoadingWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchContent from '../components/SearchScreenContent';
import SearchTextInput from '../components/SearchTextInput';
import getSearchScreenStyles from '../styles/index.web';
import { SearchViewProps, SEARCH_TEST_IDS } from '../types';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../../navigation/constants';

const homeLink = SOTT_DEFAULT_WEB_SCREENS.Home.getLink();

const SearchScreen = ({
    autoFocus = true,
    canSearch = true,
    fixedGrid = false,
    gridItemWidth,
    initialData = [],
    isLoading = false,
    isSearchBarFocused,
    items = [],
    maxSearchQuery,
    onCancelPress = () => undefined,
    onChangeText,
    onDropdownItemPress,
    onDropdownItemRemovePress,
    onSearchBarBlur,
    onSearchBarFocus,
    onSubmitEditing,
    overrideInitialDataLayout = null,
    pastSearches,
    query: searchQuery,
    styles: getStyles = getSearchScreenStyles,
    suggestedQueries = [],
    navigation,
    route,
    shouldDisplayGridLayout,
    totalFoundItems,
}: SearchViewProps) => {
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const inputRef = React.createRef<TextInputRefType>();

    return (
        <View style={styles.app}>
            <ScrollView>
                <View style={styles.topContainer}>
                    <CornerLogo
                        linkProps={homeLink}
                        styles={() => {
                            const defaultStyles = getCornerLogoStyles();
                            return {
                                ...defaultStyles,
                                holder: { ...defaultStyles.holder, ...styles.logoHolder },
                                image: styles.logoImage,
                            };
                        }}
                    />
                </View>
                <View style={styles.inputWrap}>
                    <SearchTextInput
                        inputRef={inputRef}
                        onChangeText={onChangeText}
                        placeholder={t('search.title') as string}
                        placeholderTextColor={`${theme.color.textPrimary}40`}
                        value={searchQuery}
                        onPress={onCancelPress}
                        autoFocus={autoFocus}
                        isSearchBarFocused={isSearchBarFocused}
                        onSearchBarFocus={onSearchBarFocus}
                        onSearchBarBlur={onSearchBarBlur}
                        onSubmitEditing={onSubmitEditing}
                        maxLength={maxSearchQuery}
                    />
                </View>
                <LoadingWrapper
                    isLoading={isLoading && canSearch}
                    wrapperStyle={styles.searchWrapper}
                    wrapperTestId="SearchScreen.View.4"
                    loaderProps={{
                        testID: 'SearchScreen.ActivityIndicator.1',
                        size: 'large',
                        style: styles.indicator,
                    }}
                >
                    <>
                        {suggestedQueries?.length > 0 && searchQuery?.length !== 0 && (
                            <View
                                testID={SEARCH_TEST_IDS.SEARCH_SUGGESTED_CONTAINER}
                                style={styles.headerContainer}
                            >
                                <Text
                                    testID={SEARCH_TEST_IDS.SEARCH_SUGGESTED_HEADER_TEXT}
                                    style={styles.subheaderText}
                                >
                                    {t('search.doYouMean')}
                                </Text>
                                {suggestedQueries.map((item, index) => (
                                    <Interactable
                                        testID={`${SEARCH_TEST_IDS.SEARCH_SUGGESTED_QUERY}-${index}`}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${item}-${index}`}
                                        onPress={() => onChangeText?.(item)}
                                    >
                                        <View style={styles.suggestedSearchContainer}>
                                            <Text
                                                testID={SEARCH_TEST_IDS.SEARCH_SUGGESTED_QUERY_TEXT}
                                                style={styles.suggestedSearchText}
                                                numberOfLines={1}
                                            >{`${item}`}</Text>
                                            {index < suggestedQueries.length - 1 && (
                                                <Text
                                                    style={[styles.verticalBarAfterSuggestedItem]}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            )}
                                        </View>
                                    </Interactable>
                                ))}
                            </View>
                        )}
                        {searchQuery?.length === 0 && (
                            <View style={styles.dropdownContainer}>
                                <SearchDropdown
                                    items={pastSearches}
                                    onDropdownItemPress={onDropdownItemPress}
                                    onRemovePress={onDropdownItemRemovePress}
                                />
                            </View>
                        )}
                        <View
                            testID={SEARCH_TEST_IDS.SEARCH_CONTENT_CONTAINER}
                            style={styles.searchContentContainer}
                        >
                            <SearchContent
                                items={items}
                                initialData={initialData}
                                query={searchQuery}
                                overrideInitialDataLayout={overrideInitialDataLayout}
                                searchEnabled={canSearch}
                                gridItemWidth={gridItemWidth}
                                fixedGrid={fixedGrid}
                                navigation={navigation}
                                route={route}
                                shouldDisplayGridLayout={shouldDisplayGridLayout}
                                isLoading={isLoading}
                                totalFoundItems={totalFoundItems}
                            />
                        </View>
                    </>
                </LoadingWrapper>
            </ScrollView>
        </View>
    );
};

export { getSearchScreenStyles };
export default overridable(SearchScreen, 'SearchScreen');
