import React from 'react';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View, Text } from '@24i/nxg-sdk-quarks';
import PackshotGrid from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { isWebView } from '@24i/nxg-sdk-higgs/src/utils';

import getSeeAllScreenStyles from '../styles/index.web';
import { SeeAllScreenWebProps, SEE_ALL_TEST_IDS } from '../types';

const DEFAULT_MARGIN_TOP = 102;
const WEB_VIEW_MARGIN_TOP = 20;

const SeeAllScreen = ({
    assets,
    assetsLabel,
    styles: getStyles = getSeeAllScreenStyles,
    isLoading,
    gridContainerRef,
    displayType,
    handlePackshotPress,
}: SeeAllScreenWebProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const marginTop = isWebView() ? WEB_VIEW_MARGIN_TOP : DEFAULT_MARGIN_TOP;

    return (
        <View style={styles.holder} ref={gridContainerRef} testID={SEE_ALL_TEST_IDS.CONTAINER}>
            <View style={{ ...styles.pickerHolder, marginTop }}>
                <Text style={styles.sectionTitle} testID={SEE_ALL_TEST_IDS.TITLE}>
                    {assetsLabel}
                </Text>
            </View>
            {/* @ts-ignore */}
            <PackshotGrid
                items={assets}
                onItemPress={handlePackshotPress}
                displayType={displayType}
            />
            {isLoading && <Loader />}
        </View>
    );
};

export default SeeAllScreen;
