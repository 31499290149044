/*
@PRODUCT_FEATURE: H 1.2, H 1.3.0, H 1.4
*/
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import Loader from '@24i/nxg-sdk-gluons/src/components/ui/Loader';
import { BlockModalTypes } from '@24i/nxg-sdk-smartott/src/components/BlockedModal/types';
import useBlockedModal from '@24i/nxg-sdk-smartott/src/components/BlockedModal/hooks/useBlockedModal';
import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import { isWebView } from '@24i/nxg-sdk-higgs/src/utils';

import View from './View';
import {
    defaultRenderEpisodePicker,
    defaultRenderPackshotGrid,
    defaultRenderDetailsTab,
} from './components/BottomPart';
import { useViewModel } from './viewModel';
import getDetailsScreenStyles from './styles';
// use when SSR is re-implemented
// import * as prerenderFunctions from '../../pages/details/[type]/[id]';
import DetailsScreenContent from './components/DetailsScreenContent';
import EpisodePicker from './components/EpisodePicker';
import { DetailsScreenProps } from './types';

const Main = (props: DetailsScreenProps) => {
    const viewModelProps = useViewModel(props);
    const { openBlockedModal } = useBlockedModal();
    const { hasAgeBlocker, hasAdultBlocker, triggerPinControl } = useAssetPinControl({
        active: false,
        ...props,
        ...viewModelProps,
    });
    useEffect(() => {
        if (hasAgeBlocker || hasAdultBlocker) {
            (async () => {
                const pinValidated = await triggerPinControl();
                if (!pinValidated) {
                    openBlockedModal(BlockModalTypes.MVPD_PROTECTION_LEVEL_FULL, undefined, {
                        onModalClose: () => {
                            viewModelProps.onCloseErrorModal?.();
                        },
                    });
                }
            })();
        }
    }, [hasAgeBlocker, hasAdultBlocker]);

    if (hasAgeBlocker || hasAdultBlocker) {
        return <Loader />;
    }

    return (
        <View
            {...viewModelProps}
            // webHeader props - for web-view only
            fullWidth
            hasMargin={false}
            forceDisable={!isWebView()}
            backButtonTitle={viewModelProps.asset?.title ?? ''}
        />
    );
};

export default {
    Main,
    View,
    useViewModel,
    getDefaultStyles: getDetailsScreenStyles,
    // use when SSR is re-implemented
    // page: {
    //    details: {
    //        '[type]': {
    //            '[id]': prerenderFunctions,
    //        },
    //    },
    // },
    // components
    DetailsScreenContent,
    EpisodePicker,
    // default render functions
    defaultRenderEpisodePicker,
    defaultRenderPackshotGrid,
    defaultRenderDetailsTab,
};
